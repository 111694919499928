<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
      <bread-crumbs :items="items"></bread-crumbs>

      <tabs-comp :tabs="tabs" />
      <v-row
        class="mainrow m-2"
        :style="`direction:` + lang.dir + `;margin-top:15px !important;`"
      >
        <v-row>
          <v-col cols="12" md="8" sm="12" class="mt-4">
            <form autocomplete="off">
              <label></label>
              <b-input-group>
                <div class="myDiv">
                  <b-form-input
                    v-model="mobile"
                    append-html=""
                    :placeholder="lang.mobile"
                    single-line
                    hide-details
                    class="inborder"
                    style="background: #c3d9ff"
                  ></b-form-input>
                  <b-form-input
                    v-model="customer_name"
                    append-html=""
                    :placeholder="lang.customer_name"
                    single-line
                    hide-details
                    class="inborder"
                    style="background: #c3d9ff"
                  ></b-form-input>
                  <b-form-select
                    style="display:none"
                    v-model="industrial"
                    append-html=""
                    :options="industrials"
                    single-line
                    hide-details
                    class="selborder"
                  ></b-form-select>
                  <b-form-input
                    v-model="city"
                    append-html=""
                    :placeholder="lang.city"
                    single-line
                    hide-details
                    class="inborder"
                    style="background: #c3d9ff"
                  ></b-form-input>
                </div>
                <b-input-group-prepend>
                  <span
                    class="input-group-text"
                    @click="getCustomer()"
                    :style="
                      `cursor:pointer;background:black !important;color:#fff;padding: 4px 16px;border-radius: 0;border: 1px solid #000;border-` +
                      lang.algin +
                      `: 0;`
                    "
                  >
                    {{ lang.search }}<i class="fas fa-search"></i
                  ></span>
                </b-input-group-prepend>
              </b-input-group>
            </form>
          </v-col>
          <v-col cols="12" md="4" sm="12" class="text-end">
            <b-button
              v-b-toggle.add_customer
              class="btn-sm btn btn-success"
              style="
                margin-top: 25px;
                width: 150px;
                background: transparent;
                border: none;
                color: #000;
              "
              id="multiply-button"
            >
              <div style="width: 150px" :class="`multiply-button-content-` + lang.lalgin">
                {{ lang.add_customer }}
              </div>
            </b-button>
          </v-col>

          <v-col cols="12" :style="`direction:` + lang.dir + `;`">
            <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              width="100%"
              id="MainTable"
              hide-default-footer
              fixed-header
            >
              <template v-slot:item="row">
                <tr>
                  <td style="width: 100px; text-align: center">
                    {{ row.item.customerid }}
                  </td>
                  <td style="width: 100px; text-align: center">{{ row.item.mobile1 }}</td>
                  <td style="text-align: center">{{ row.item.full_name }}</td>
                  <td
                    :style="`border:2px solid #fff !important;width:100px;text-align:center;background:${row.item._customer_type_bg} !important;color:${row.item._customer_type_color} !important;`"
                  >
                    {{ custType(row.item.customer_type) }}
                  </td>
                  <td style="text-align: center">{{ row.item.company_name }}</td>
                  <td style="text-align: center">{{ row.item.city }}</td>
                  <td
                    style="
                      cursor: pointer;
                      width: 85px;
                      background: blue;
                      border: 2px solid #fff !important;
                    "
                    v-if="!$store.state.licenseType.isBill"
                  >
                    <div
                      class="viewBTN"
                      @click="getCust(row.item.id)"
                      v-b-toggle.customer_crm
                    >
                      {{ lang.action }}
                    </div>
                  </td>
                  <td
                    style="
                      cursor: pointer;
                      width: 85px;
                      background: blue;
                      border: 2px solid #fff !important;
                    "
                    v-if="$store.state.licenseType.isBill"
                  >
                    <div
                      class="viewBTN"
                      @click="getCus(row.item.id)"
                      v-b-toggle.add_convert
                    >
                      {{ lang.action }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" style="margin-bottom: 25px; margin-top: -15px">
            <v-container>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  value="15"
                  :length="pageCount"
                  color="#000"
                  @input="getNext(page)"
                ></v-pagination>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-row>
      <add-customer />

      <client-statements ref="clientStatments" />
      <customerCRM ref="customerRef" />
      <convertClient ref="convertClient" />
    </div>
    <Footer />
  </div>
</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs.vue";
import axios from "axios";
import AddCustomer from "@/components/addCustomer.vue";
// import AddQuotation from '@/components/addQuotation.vue'
// import AddInvoice from '@/components/addInvoice.vue'
import TabsComp from "@/components/tabsComp.vue";
// import UpdateCustomer from '@/components/update-customer.vue'
import HeaderPortrate from "@/components/Header-Portrate.vue";
import Footer from "@/components/Footer.vue";
import ClientStatements from "@/components/clientStatements.vue";
import ExportsPrint from "@/components/exports-print.vue";
import customerCRM from "@/components/customerCRM.vue";
import convertClient from "@/components/convertClient.vue";
export default {
  name: "clientlist",
  components: {
    convertClient,
    customerCRM,
    BreadCrumbs,
    AddCustomer,
    TabsComp,
    HeaderPortrate,
    Footer,
    ClientStatements,
    ExportsPrint,
  },
  data() {
    return {
      industrial: 0,
      mobile: "",
      customer_name: "",
      city: "",
      cpage: 0,
      openQuot: false,

      page: 1,
      pageCount: 0,
      search: "",
      tablerows: [],
      opselected: {},
      ifpselected: {},
      invorquot: 0,
      tab1: 1,
      tab2: 2,
      tab3: 3,
      ccustomer: {},
      dustrial: [],
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    isERP: function(){
        return this.$store.state.licenseType.activemenu
    },
    items: function () {
      return {
        text: this.lang.customers,
        disabled: true,
        href: "/clientList",
      };
    },
    industrials: function () {
      let t = [{ text: this.lang.industrial, value: 0 }];
      for (let i = 0; i < this.dustrial.length; i++) {
        t.push({
          text: this.dustrial[i][`name_` + this.lang.langname],
          value: this.dustrial[i].value,
        });
      }
      return t;
    },
    currentCus: function () {
      if (this.invorquot == 1) {
        return {
          id: this.opselected.id,
          full_name: this.opselected.full_name,
          mobile: this.opselected.mobile1,
          company: {
            fullname: this.opselected.company_name,
            vatid: this.opselected.company_vatid,
          },
        };
      } else {
        return {
          id: this.ifpselected.id,
          full_name: this.ifpselected.full_name,
          mobile: this.ifpselected.mobile1,
          company: {
            fullname: this.ifpselected.company_name,
            vatid: this.ifpselected.company_vatid,
          },
        };
      }
    },
    headers: function () {
      let headers = [
        {
          text: this.lang.customerid,
          align: "center",
          filterable: true,
          value: "customerid",
          sortable: false,
          class: "backBlack",
        },

        {
          text: this.lang.mobile,
          align: "center",
          filterable: true,
          value: "mobile",
          sortable: false,
          class: "backBlack",
        },
        // {
        //     text: this.lang.additional_mobile, align: 'center',
        //     filterable: true, value: 'mobile1',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        {
          text: this.lang.customer_name,
          align: "center",
          filterable: true,
          value: "full_name",
          sortable: true,
          class: "backBlack",
        },
        {
          text: this.lang.customer_type,
          align: "center",
          filterable: true,
          value: "customer_type",
          sortable: false,
          class: "backBlack",
        },
        {
          text: this.lang.company_name,
          align: "center",
          filterable: true,
          value: "company_name",
          sortable: false,
          class: "backBlack",
        },
        {
          text: this.lang.city,
          align: "center",
          filterable: true,
          value: "city",
          sortable: false,
          class: "backBlack",
        },
        // {
        //     text: this.lang.industry, align: 'center',
        //     filterable: true, value: 'city',
        //     sortable: false,
        //     class: 'backBlack'
        // },

        // {
        //     text: 'حالة الفواتير', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'المهمة', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'الملاحظة', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'الموعد القادم', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },

        // {
        //     text: this.lang.add_invoice, align: 'center',
        //     filterable: false,
        //     sortable: false,
        //     class: 'backBlack',
        //     printRemove: 0
        // },

        // {
        //     text: 'الضمان', align: 'center',
        //     filterable: true, value: 'tasks',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.tasks, align: 'center',
        //     filterable: true, value: 'tasks',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.total, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.remain, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.client_statement, align: 'center',
        //     filterable: false,
        //     sortable: false,
        //     class: 'backBlack',
        //     printRemove: 1
        // },
        // {
        //     text: this.lang.remain, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.status, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        {
          text: this.lang.view,
          align: "center",
          filterable: false,
          sortable: false,
          class: "backBlack",
          printRemove: 0,
        },
      ];
      return headers;
    },
    tabs: function () {
      const menu = this.$store.getters["state"].menu;
      const license = this.$store.getters["state"].licenseType.activemenu;
      const alicense = this.$store.getters["state"].licenseType;
      let currentMenu = menu[license];
      if (!license) {
        return false;
      }
      let cMenu = { main: {}, sub: [] };
      Object.values(currentMenu).forEach((value) => {
        const cM = localStorage.getItem("currentMenu");
        if (value.main.index == cM) {
          cMenu = value.sub;
        } else if (cM == null && value.main.index == 1) {
          cMenu = value.sub;
        }
      });

      let t = [];
      Object.values(cMenu).forEach((value) => {
        if (this.$route.path == value.href) {
          value.class = "mytab";
        } else {
          value.class = "mytab4";
        }
        if (this.lang.langname == "ar") {
          value.name = value.arname;
        } else {
          value.name = value.enname;
        }
        for (let i = 0; i < value.groupid.length; i++) {
          if (value.groupid[i] == this.$store.getters["state"].group_id) {
            if (alicense[value.perms[0]] || value.perms[0] == "any") t.push(value);
          }
        }
      });

      return t;
    },
  },
  methods: {
    getStaticOption(phrase) {
      const post = new FormData();
      post.append("type", "getStaticOptions");
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", phrase);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.dustrial = response.data.results.data;
      });
    },
    getCustomer() {
      const post = new FormData();
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "getClients");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[mobile]", this.mobile);
      post.append("data[customer_name]", this.customer_name);
      post.append("data[city]", this.city);
      post.append("data[industrial]", this.industrial);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.pageCount = parseInt(parseInt(response.data.results.allres) / 15) + 1;
        this.tablerows = response.data.results.data;
      });
    },
    custType(id) {
      if (id == 1) return this.lang.individual_client;
      else if (id == 2) return this.lang.company_clinet;
      else if (id == 3) return this.lang.government_clinet;
    },
    preparedata() {
      let exdata = {
        list: "clientList",
        title: this.lang.customers,
        data: [],
      };
      for (let i = 0; i < this.tablerows.length; i++) {
        exdata.data.push(this.tablerows[i]);
      }
      // // console.log(exdata);
      this.$refs.exportbar.exportdata = exdata;
      this.$refs.exportbar.sdate = "";
      this.$refs.exportbar.edate = "";

      this.$refs.exportbar.exporttitle = "";
      this.$refs.exportbar.palte_number = "";
      this.$refs.exportbar.contact_number = "";
    },
    getCurrentCards() {
      //
    },
    upCusto(item) {
      // console.log("item" ,item);
      this.$refs.updatecustomer.customers = item;
      this.$refs.updatecustomer.customers.mobile = item.mobile1;
      this.$refs.updatecustomer.customers.mobile1 = item.mobile2;
      this.$refs.updatecustomer.customers.customer_type = item.customer_type;

      this.$refs.updatecustomer.firstUpdate();
      this.$refs.updatecustomer.changeCusType(parseInt(item.customer_type) - 1);
    },
    quotOP(item) {
      this.$refs.addQoutFloat.company = {
        full_name: item.fullname,
        mobile: item.mobile,
      };
      this.$refs.addQoutFloat.customer.id = item.id;
      this.$refs.addQoutFloat.customer.mobile = item.mobile1;
      this.$refs.addQoutFloat.customer.full_name = item.full_name;
      this.$refs.addQoutFloat.company.fullname = item.company_name;
      this.$refs.addQoutFloat.company.vatid = item.company_vatid;
    },
    InvOP(item) {
      this.$refs.addinvoices.customer.mobile = item.mobile1;
      this.$refs.addinvoices.getName();

      if (item.company_name != "") {
        this.$refs.addinvoices.customer.cctype = 2;
        this.$refs.addinvoices.changeCtype();
      } else {
        this.$refs.addinvoices.customer.cctype = 1;
        this.$refs.addinvoices.changeCtype();
      }
    },
    getCust(id){
        this.$refs.customerRef.customerid = id;
        this.$refs.customerRef.getCustomerInfo();
    },
    getCus(id) {
      this.$refs.convertClient.customerid = id;
      this.$refs.convertClient.expected_amount = "";
    },
    getNext(page) {
      this.cpage = parseInt(page) - 1;
      this.getClients();
    },
    getClients() {
      const post = new FormData();
      post.append("type", "getClients");
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[all]", 1);
      post.append("data[cpage]", this.cpage);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.pageCount = parseInt(parseInt(response.data.results.allres) / 15) + 1;
        this.tablerows = response.data.results.data;
      });
    },
    getStatement(item) {
      // this.$router.push({path: '/client-statement/'+item.id})
      let classid = parseFloat(item.customerid);
      // if(parseFloat(item.customerid) < 6000){
      //     classid = parseFloat(item.customerid) + parseFloat(6000);
      // }
      this.$refs.clientStatments.accountn.full_name = item.full_name;
      this.$refs.clientStatments.accountn.customerid = classid;
      this.$refs.clientStatments.getReport();
      this.$refs.clientStatments.getReport();
    },
  },
  created() {
    this.getClients();
    this.getStaticOption("inDustrial");
  },
};
</script>
<style>
.myDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
